import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { skyBlue, white, offWhite } from "../../constants/colors"
import { largeDesktop, desktop, tablet, smallMobile } from "../../constants/screenSizes"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import Block from "../../components/landing-page/Block"
import Part from "../../components/landing-page/Part"
import Subheading from "../../components/landing-page/Subheading"
import Title from "../../components/landing-page/Title"
import Description from "../../components/landing-page/Description"
import GLink from "../../../gatsby/GLink"
import Globe from "../../../svg/illustration-ecosystem-noicon.svg"
import EmployersConscience from "../../../svg/icon-employers-conscience-big.svg"
import IndependentBrands from "../../../svg/icon-independent-brands-big.svg"
import SocialShoppers from "../../../svg/icon-social-shoppers-big.svg"

const verticalPadding = 140

const StyledBlock = styled(Block)`
  padding: ${verticalPadding}px 40px;
  @media only screen and (max-width: ${desktop}px) {
    padding: 80px 40px;
    flex-direction: column;
    align-items: stretch;
  }
  @media (max-width: ${tablet}px) {
    padding: 80px 28px;
  }
  @media (max-width: ${smallMobile}px) {
    padding: 80px 20px;
  }
`

const LeftPart = styled(Part)`
  height: 100%;
  @media only screen and (max-width: ${largeDesktop}px) {
    width: 40%;
  }
  @media only screen and (max-width: ${desktop}px) {
    margin: 80px auto;
    width: fit-content;
    height: fit-content;
  }
  @media only screen and (max-width: ${tablet}px) {
    margin: 60px auto;
  }
`

const RightPart = styled(Part)`
  @media only screen and (max-width: ${desktop}px) {
    width: 100%;
  }
`

const Eyebrow = styled(Subheading)`
  width: fit-content;
  display: ${({ isDesktop }) => (isDesktop ? "inline-block" : "none")};
  @media only screen and (max-width: ${desktop}px) {
    display: ${({ isDesktop }) => (isDesktop ? "none" : "inline-block")};
    font-size: 14px;
  }
`

const StyledDescription = styled(Description)<{ isSemibold: boolean }>`
  font-size: 16px;
  ${({ isSemibold }) => isSemibold && "font-weight: 600;"}
`

const linkStyle = `
  color: ${white};
  text-decoration: underline;
`

const InternalLink = styled(GLink)`
  ${linkStyle}
`

const ExternalLink = styled.a`
  ${linkStyle}
`
const imageWidth = "50vw - 80px"
const mobileImageWidth = "100vw - 40px"

const EcosystemImage = styled.div`
  width: 577px;
  height: 504px;
  background: ${skyBlue} url(${Globe}) no-repeat left center;
  background-attachment: cover;
  background-size: 462px 504px;
  @media only screen and (max-width: ${largeDesktop}px) {
    width: calc(${imageWidth});
    height: calc((${imageWidth}) * 0.88);
    background-size: 80% 100%;
  }
  @media only screen and (max-width: ${desktop}px) {
    width: 520px;
    height: 455px;
    background-size: 416px 455px;
  }
  @media only screen and (max-width: ${smallMobile}px) {
    width: calc(${mobileImageWidth});
    height: calc((${mobileImageWidth}) * 0.88);
    background-size: 80% 100%;
  }
`

export const ShopersIcon = styled.img`
  display: block;
  position: absolute;
  top: 38%;
  left: 39%;
  width: 116px;
  height: 116px;
  transform: translate(-100px, -170px);
  @media only screen and (max-width: ${largeDesktop}px) {
    transform: translate(-7vw, -13vw);
    width: calc((${imageWidth}) * 0.2);
    height: calc((${imageWidth}) * 0.2);
  }
  @media only screen and (max-width: ${desktop}px) {
    display: none;
  }
`

export const ShopersIconSmall = styled.img`
  display: none;
  @media only screen and (max-width: ${desktop}px) {
    display: block;
    position: absolute;
    top: 0;
    left: 78px;
    width: 140px;
    height: 140px;
  }
  @media only screen and (max-width: ${smallMobile}px) {
    top: 0;
    left: calc((${mobileImageWidth}) * 0.18);
    width: calc((${mobileImageWidth}) * 0.27);
    height: calc((${mobileImageWidth}) * 0.27);
  }
`

export const IndependentIcon = styled.img`
  display: block;
  position: absolute;
  top: 38%;
  left: 39%;
  width: 116px;
  height: 116px;
  transform: translate(200px, 0) scale(1.354);
  @media only screen and (max-width: ${largeDesktop}px) {
    transform: translate(15vw, 0) scale(1.354);
    width: calc((${imageWidth}) * 0.2);
    height: calc((${imageWidth}) * 0.2);
  }
  @media only screen and (max-width: ${desktop}px) {
    display: none;
  }
`

export const IndependentIconSmall = styled.img`
  display: none;
  @media only screen and (max-width: ${desktop}px) {
    display: block;
    position: absolute;
    right: 0;
    top: 150px;
    width: 140px;
    height: 140px;
  }
  @media only screen and (max-width: 600px) {
    right: 60px;
  }
  @media only screen and (max-width: ${smallMobile}px) {
    right: 0;
    top: calc((${mobileImageWidth}) * 0.3);
    width: calc((${mobileImageWidth}) * 0.27);
    height: calc((${mobileImageWidth}) * 0.27);
  }
`

export const EmployersIcon = styled.img`
  display: block;
  position: absolute;
  top: 38%;
  left: 39%;
  width: 116px;
  height: 116px;
  transform: translate(-100px, 170px);
  @media only screen and (max-width: ${largeDesktop}px) {
    transform: translate(-7vw, 13vw);
    width: calc((${imageWidth}) * 0.2);
    height: calc((${imageWidth}) * 0.2);
  }
  @media only screen and (max-width: ${desktop}px) {
    display: none;
  }
`

export const EmployersIconSmall = styled.img`
  display: none;
  @media only screen and (max-width: ${desktop}px) {
    display: block;
    position: absolute;
    bottom: 12px;
    left: 78px;
    width: 140px;
    height: 140px;
  }
  @media only screen and (max-width: ${smallMobile}px) {
    bottom: 0;
    left: calc((${mobileImageWidth}) * 0.18);
    width: calc((${mobileImageWidth}) * 0.27);
    height: calc((${mobileImageWidth}) * 0.27);
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 498px;
  width: 100%;
  gap: 30px;
  &:not(:first-child) {
    padding-top: 325px;
  }
  @media only screen and (max-width: ${largeDesktop}px) {
    margin-left: calc((${imageWidth}) * 0.16);
    max-width: calc((${imageWidth}) * 0.86);
  }
  @media only screen and (max-width: ${desktop}px) {
    margin-left: 0;
    max-width: 100%;
    &:not(:first-child) {
      padding-top: 40px;
    }
  }
  @media only screen and (max-width: ${tablet}px) {
    gap: 16px;
  }
`

const ecoItems = [
  {
    title: "Independent impact-led UK brands",
    description1: "Social enterprises are small businesses frustrated with the state of affairs.",
    description2: (
      <>
        Their social mission is at the heart of what they do, so they’re in the business of making a
        real difference in the world with every purchase. We bring together the very best of them
        and verify their impact through third parties such as{" "}
        <ExternalLink href="https://www.goodmarket.global/info/" target="_blank">
          Good Market
        </ExternalLink>
        ,{" "}
        <ExternalLink href="https://www.socialenterprise.org.uk/" target="_blank">
          SEUK
        </ExternalLink>{" "}
        and{" "}
        <ExternalLink href="https://www.bcorporation.net/en-us/" target="_blank">
          B Corp
        </ExternalLink>
        . It's our ambition to help them create even more positive impact.
      </>
    ),
  },
  {
    title: "Socially conscious shoppers",
    description1:
      "Feeling frustrated with unethical companies, greenwashing, online orders that show up in plastic packaging?",
    description2: (
      <>
        We want to make it easier for you to shop without compromising your values or sacrificing on
        quality. We promise to prioritise sustainability and transparency in everything we do, as do
        our brand partners.&nbsp;
        <InternalLink to="/impact-and-sustainability/">
          Learn more about our impact and sustainability policies
        </InternalLink>
        .
      </>
    ),
  },
  {
    title: "Employers with a conscience",
    description1:
      "It’s long been known companies are big drivers of change. We help them to buy more ethically from purpose-driven brands, as well as measure their positive impact.",
    description2: (
      <>
        Everything from employee Christmas gifts to coffee in the office kitchen can help to get us
        closer to the&nbsp;
        <ExternalLink href="https://sdgs.un.org/" target="_blank">
          UN’s Sustainable Development Goals
        </ExternalLink>
        . &nbsp;We see a future where buying social is the ordinary, expected — and measured.&nbsp;
        <InternalLink to="/refer-us-to-your-company/">
          Get your company to join the mission
        </InternalLink>
        .
      </>
    ),
  },
]

const ScrollEcosystem = (): JSX.Element => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false)

  const ecoWrapperRef = useRef<HTMLDivElement>(null)
  const ecoImage = useRef<HTMLDivElement>(null)
  const shopersIconRef = useRef<HTMLImageElement>(null)
  const independentIconRef = useRef<HTMLImageElement>(null)
  const employersIconRef = useRef<HTMLImageElement>(null)
  const textRefs = useRef<HTMLDivElement[]>([])

  const animations = []

  const animationOnScroll = () => {
    const position1stTextFromTop = textRefs.current[0].getBoundingClientRect().top
    const firstTextRefHeight = textRefs.current[0].offsetHeight
    const secondTextRefHeight = textRefs.current[1].offsetHeight

    const duration = firstTextRefHeight + secondTextRefHeight
    let animationTime = -position1stTextFromTop

    // handle the scroll accuracy
    if (-position1stTextFromTop < 0) {
      animationTime = 0
    }
    if (-position1stTextFromTop > duration) {
      animationTime = duration
    }

    if (!animations || animations.length === 0) {
      const getTransformProps = (deg: string, rotate: string, scale: string) => ({
        transform: `rotate(${deg}deg) translateX(173%) rotate(${rotate}deg) scale(${scale})`,
      })

      const animationItems = [
        {
          ref: shopersIconRef.current,
          transformOptions: [
            getTransformProps("-120", "120", "1"),
            getTransformProps("0", "0", "1.354"),
            getTransformProps("120", "-120", "1"),
          ],
        },
        {
          ref: independentIconRef.current,
          transformOptions: [
            getTransformProps("0", "0", "1.354"),
            getTransformProps("120", "-120", "1"),
            getTransformProps("240", "-240", "1"),
          ],
        },
        {
          ref: employersIconRef.current,
          transformOptions: [
            getTransformProps("120", "-120", "1"),
            getTransformProps("240", "-240", "1"),
            getTransformProps("360", "-360", "1.354"),
          ],
        },
      ]

      animationItems.forEach(({ ref, transformOptions }) => {
        const options = { duration, fill: "both" } as KeyframeAnimationOptions

        const animation = ref?.animate(transformOptions, options)
        animations.push(animation)
        animation?.pause()
        animation!.currentTime = animationTime
      })
    }

    animations.forEach(animation => {
      animation!.currentTime = animationTime
    })
  }

  const pinLeftPart = () => {
    const gapForHeader = 40
    const positionFromTop = ecoWrapperRef.current.getBoundingClientRect().top - gapForHeader
    const wrapperHeight = ecoWrapperRef.current.offsetHeight
    const ecoImageHeight = ecoImage.current.offsetHeight
    const positionFromBottom = positionFromTop + wrapperHeight - ecoImageHeight
    const verticalPaddings = verticalPadding * 2

    animationOnScroll()

    if (positionFromTop < 0 && positionFromBottom > verticalPaddings) {
      ecoImage.current.style.position = "fixed"
      ecoImage.current.style.top = `${verticalPadding + gapForHeader}px`
    } else {
      ecoImage.current.style.position = "absolute"
      ecoImage.current.style.top = positionFromTop > 0 ? `${verticalPadding}px` : "unset"
      ecoImage.current.style.bottom = positionFromTop > 0 ? "unset" : `${verticalPadding}px`
    }
  }

  const cancelScrollAnimations = () => {
    animations.forEach(animation => {
      animation.cancel()
    })
    window.removeEventListener("scroll", pinLeftPart)
  }

  const handleResize = () => {
    const isDesktopResolution = window.innerWidth > desktop
    if (isDesktopResolution !== isDesktop) {
      setIsDesktop(isDesktopResolution)
    }

    if (isDesktop) {
      if (animations.length) {
        cancelScrollAnimations()
      }
      window.addEventListener("scroll", pinLeftPart)
    } else {
      // reset to initial image state
      cancelScrollAnimations()

      ecoImage.current.style.position = "relative"
      ecoImage.current.style.top = "0"
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("scroll", pinLeftPart)
      window.removeEventListener("resize", handleResize)
    }
  }, [isDesktop])

  const eyebrow = "The Social Supermarket ecosystem"

  return (
    <div ref={ecoWrapperRef}>
      <ColorWrapper background={skyBlue}>
        <Fade when>
          <StyledBlock>
            <Eyebrow color={offWhite} isBordered borderColor={white}>
              {eyebrow}
            </Eyebrow>
            <LeftPart>
              <EcosystemImage ref={ecoImage}>
                <ShopersIcon src={SocialShoppers} alt="Social Shoppers" ref={shopersIconRef} />
                <IndependentIcon
                  src={IndependentBrands}
                  alt="Independent Brands"
                  ref={independentIconRef}
                />
                <EmployersIcon
                  src={EmployersConscience}
                  alt="Employers Conscience"
                  ref={employersIconRef}
                />
                <ShopersIconSmall src={SocialShoppers} alt="Social Shoppers" />
                <IndependentIconSmall src={IndependentBrands} alt="Independent Brands" />
                <EmployersIconSmall src={EmployersConscience} alt="Employers Conscience" />
              </EcosystemImage>
            </LeftPart>
            <RightPart>
              {ecoItems.map(({ title, description1, description2 }) => (
                <Text ref={ref => textRefs.current.push(ref)} key={title}>
                  <Eyebrow color={offWhite} isBordered borderColor={white} isDesktop>
                    {eyebrow}
                  </Eyebrow>
                  <Title color={white}>{title}</Title>
                  <StyledDescription isSemibold color={white}>
                    {description1}
                  </StyledDescription>
                  <StyledDescription color={white}>{description2}</StyledDescription>
                </Text>
              ))}
            </RightPart>
          </StyledBlock>
        </Fade>
      </ColorWrapper>
    </div>
  )
}

export default ScrollEcosystem
